import React, {useState} from "react";
import axios from "axios";
// const axios = require('axios');
// react-bootstrap components
import background from "../assets/img/pexels-pixabay-434334.jpg";
import { Badge, Button, Card, Form, Navbar, Nav, Container, Row, Col } from "react-bootstrap";
async function sendQuery(data) {
  return new Promise((resolve, reject) => {
    let head = { 'Content-Type':'application/json' };
    axios.post('https://newmis.livechek.com/api/query?type=cals', data, { headers: head}).then(res => {
      resolve(res);
    }).catch(err => {
      console.log('Error : ', err);
      reject(err);
    });
  })
  
}
function User() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [query, setQuery] = useState("");
  function validateForm() {
    return name.length > 2 && query.length > 5 && validateEmail(email);
  }
  async function handleSubmit(event) {
    event.preventDefault();
    let data = {
      name: name,
      email: email,
      message: query,
    };
    let head = await sendQuery(data);
    if(head.status && head.status == 200){
      alert('Query sent Successfully');
    } 
    window.location.reload(false);
  }
  function validateEmail(email) {
    let re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (re.test(email)) return true;
    else return false;
  }
  return (
    <>
      <Container fluid style={{ height:'100%', background: 'rgb(3 81 117)', color:'#ffffff'}} className="wrapper">
        <Row >
          <Col md="2" ></Col>
          <Col md="8" >
            <h4 className="text-center"><br/><br/>coming soon...<br/><br/></h4>
            <h1 className="text-center" >climate action labs<br/><br/></h1>
            <h4 className="text-center"> climate action labs is focused on working with industry and society on systemic transformtion 
            towards reducing co2 emissions and controlling global warming <br/><br/><br/><br/></h4> 
          
            <Row>
              <Col md="6" sm="6" xs="6"></Col>
              <Col md="6" sm="6" xs="6">
                {/* <p className="text-center" >contact us</p> */}
                <Form onSubmit={(e)=>handleSubmit(e)}>
                  {/* <Row>
                    <Col className="pr-1" md="5">
                      <Form.Group>
                        <label>Company (disabled)</label>
                        <Form.Control
                          defaultValue="Creative Code Inc."
                          disabled
                          placeholder="Company"
                          type="text"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="px-1" md="3">
                      <Form.Group>
                        <label>Username</label>
                        <Form.Control
                          defaultValue="michael23"
                          placeholder="Username"
                          type="text"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="4">
                      <Form.Group>
                        <label htmlFor="exampleInputEmail1">
                          Email address
                        </label>
                        <Form.Control
                          placeholder="Email"
                          type="email"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  backgroundImage:"url(" + background + ")",backgroundRepeat: 'no-repeat', backgroundSize: 'cover'
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>First Name</label>
                        <Form.Control
                          defaultValue="Mike"
                          placeholder="Company"
                          type="text"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="6">
                      <Form.Group>
                        <label>Last Name</label>
                        <Form.Control
                          defaultValue="Andrew"
                          placeholder="Last Name"
                          type="text"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row> */}
                  <Row className="pull-right">
                    <Col md="12" style={{padding:'0px', display:'flex'}}>
                      <Form.Group style={{padding:'0px', display:'flex',width:'100%'}}>
                        {/* <label>Name</label> */}
                        <Form.Control
                          placeholder="name"
                          type="text"
                          style={{height:'auto',padding:'0px',background:'transparent',color:'#ffffff',margin:'0px 0px 0px 50%',border:'none',fontSize:'smaller'}}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        ></Form.Control>
                        <br/>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12" style={{padding:'0px', display:'flex'}}>
                      <Form.Group style={{padding:'0px', display:'flex',width:'100%'}}>
                        {/* <label>Email</label> */}
                        <Form.Control
                          placeholder="email"
                          type="email"
                          style={{height:'auto',padding:'0px',background:'transparent',color:'#ffffff',margin:'0px 0px 0px 50%',border:'none',fontSize:'smaller'}}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        ></Form.Control>
                        <br/>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col className="pr-1" md="4">
                      <Form.Group>
                        <label>City</label>
                        <Form.Control
                          defaultValue="Mike"
                          placeholder="City"
                          type="text"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="px-1" md="4">
                      <Form.Group>
                        <label>Country</label>
                        <Form.Control
                          defaultValue="Andrew"
                          placeholder="Country"
                          type="text"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="4">
                      <Form.Group>
                        <label>Postal Code</label>
                        <Form.Control
                          placeholder="ZIP Code"
                          type="number"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col md="12" style={{padding:'0px', display:'flex'}}>
                      <Form.Group style={{padding:'0px', display:'flex',width:'100%'}}>
                        {/* <label>Message</label> */}
                        <Form.Control
                          cols="80"
                          style={{padding:'0px',background:'transparent',color:'#ffffff',margin:'0px 0px 0px 50%',border:'none',fontSize:'smaller'}}
                          placeholder="message"
                          rows="2"
                          as="textarea"
                          value={query}
                          onChange={(e) => setQuery(e.target.value)}
                        ></Form.Control>
                        <br/>
                      </Form.Group>
                    </Col>
                  </Row>
                  <div >
                    <Button
                      className="btn-fill btn-sm"
                      type="submit"
                      style={{padding:"0px",background:"transparent", border:'none', margin:'0px 0px 0px 50%'}}
                      variant="primary"
                      disabled={!validateForm()}
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
                
              </Col>
            </Row>
            <p className="text-center" style={{fontSize:'small', color:'#ffffff', opacity: 0.6}}> <br/>
                  {/* #13, 3rd Floor, Sant Nagar, East of Kailash, New Delhi – 110065 <br/>
                  Phone: 011-46015666, +919810755099 <br/> */}
              rm@climateactionlabs.org <br/>
              <span >&copy; {new Date().getFullYear()}</span>&nbsp;<span >ca labs foundation</span>
            </p> 
              {/* <nav>
                <p> style={{background:'rgb(139, 236, 121)'}}
                  #13, 3rd Floor, Sant Nagar, East of Kailash, New Delhi – 110065 <br/>
                  Phone: 011-46015666, +919810755099<br/>
                  Email: <a href="https://www.rm@climateeventurepartners.com">rm@climateeventurepartners.com</a>
                </p>
                <p className="copyright text-center">
                  © {new Date().getFullYear()}{" "} <a href="">Climate Venture Partners</a>
                </p>
              </nav> */}
          </Col>
          {/* <Col md="4">
            <Card className="card-user">
              <div className="card-image">
                <img
                  alt="..."
                  src={require("assets/img/photo-1431578500526-4d9613015464.jpeg")}
                ></img>
              </div>
              <Card.Body>
                <div className="author">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="avatar border-gray"
                      src={require("assets/img/faces/face-3.jpg")}
                    ></img>
                    <h5 className="title">Mike Andrew</h5>
                  </a>
                  <p className="description">michael24</p>
                </div>
                <p className="description text-center">
                  "Lamborghini Mercy <br></br>
                  Your chick she so thirsty <br></br>
                  I'm in that two seat Lambo"
                </p>
              </Card.Body>
              <hr></hr>
              <div className="button-container mr-auto ml-auto">
                <Button
                  className="btn-simple btn-icon"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  variant="link"
                >
                  <i className="fab fa-facebook-square"></i>
                </Button>
                <Button
                  className="btn-simple btn-icon"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  variant="link"
                >
                  <i className="fab fa-twitter"></i>
                </Button>
                <Button
                  className="btn-simple btn-icon"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  variant="link"
                >
                  <i className="fab fa-google-plus-square"></i>
                </Button>
              </div>
            </Card>
          </Col> */}
        </Row>
      </Container>
    </>
  );
}

export default User;
